jQuery(document).ready(function ($) {
    consoleMessage: {
        console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
    if (!Modernizr.objectfit) {
      $('.object-fit').each(function () {
        var $container = $(this),
            imgUrl = $container.find('img').prop('src');
        if (imgUrl) {
            $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('not-compatible');
        }
      });
    }
	}



	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}

	setCurrentonSinglePosts: {
		if ( $('body').hasClass('single-post') ) {
      $('.menu-item-17').addClass('current_page_item');
    }
    if ( $('body').hasClass('single-session') ) {
      $('.menu-item-19').addClass('current_page_item');
    }
    if ( $('body').hasClass('single-publication') ) {
      $('.menu-item-18').addClass('current_page_item');
    }
	}

	anchorlinks: {
		$(document).on('click', '.js-scroll-down', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('.news-section').offset().top
			}, 600);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});

		$('a:not(.magnific-video, .email-link)').each(function () {
			var a = new RegExp('/' + window.location.host + '/');

			if (!a.test(this.href)) {
				$(this).click(function (event) {
				event.preventDefault();
				window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}


	magnific: {
		// INLINE
		$('.inline-popup').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    // GALLERY SINGLE IMAGE
    $(".magnific-gallery-single-image").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      mainClass: "mfp-img-single",
      image: {
        verticalFit: true,
        titleSrc: function titleSrc(item) {
          return item.el.attr("title");
        },
      },
      gallery: {
        enabled: false,
      },
    });       

    // GALLERY IMAGE
    $('.magnific-gallery-image').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
        verticalFit: true,
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      },
      gallery:{
        enabled:true
      }
    });

	}


  accordion: {
    if ($(".accordion-block").length) {
      // Close accordions on all pages
      $(".accordion-content").css("display", "none"); // All items closed.
    
      $(".accordion-block")
        .find(".accordion-trigger")
        .on("click", function () {
          console.log("accordion");

          // Close only the items in this accordion.
          $(this)
            .parent()
            .siblings()
            .find(".accordion-trigger")
            .next()
            .slideUp("fast"); // reset
          $(this)
            .parent()
            .siblings()
            .find(".accordion-trigger")
            .removeClass("open"); // reset


          if ($(this).hasClass("open")) {
            $(this).next().slideUp("fast");
            $(this).removeClass("open");
            console.log("closed");
          } else {
            $(this).next().slideDown("fast");
            $(this).addClass("open");
            console.log("opened");
          }
        });
    }
  }


	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 60;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});
